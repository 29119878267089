// "Hot Module Replacement" enabled environment

export const environment = {
    production: false,
    hmr: false,
    appConfig: 'appconfig.uat.json',
    defaults: {
        dateLocale: 'en-gb'
    }
};
